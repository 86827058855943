<template>
<div v-if="terms">
    <slot name="outer" v-bind="{ terms }"></slot>
    <v-dialog v-model="show" max-width="600">
        <v-card>
            <v-card-title class="subtitle-1">{{ terms.subject }}</v-card-title>
            <v-divider/>
            <v-card-text class="pa-0">
                <v-responsive max-height="70vh" class="overflow-auto pa-4">
                    <p v-html="terms?.content?.replace?.(/\n/g, '<br/>')">
                        약관 내용 전달
                    </p>
                </v-responsive>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="grey" @click="cancel()">취소</v-btn>
                <v-btn text color="primary" @click="agree()">동의</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            show: false,

            terms: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let [ terms ] = await api.v1.terms.gets({ params: { code: "top" }}).then(({ termsList }) => termsList);
            this.terms = terms;
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        cancel(){
            this.$emit("cancel");
            this.close();
        },
        agree(){
            this.$emit("agree");
            this.show = false;
        }
    }
}
</script>