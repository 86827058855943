var render = function render(){
  var _vm$terms, _vm$terms$content, _vm$terms$content$rep;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.terms ? _c('div', [_vm._t("outer", null, null, {
    terms: _vm.terms
  }), _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.terms.subject))]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-responsive', {
    staticClass: "overflow-auto pa-4",
    attrs: {
      "max-height": "70vh"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s((_vm$terms = _vm.terms) === null || _vm$terms === void 0 ? void 0 : (_vm$terms$content = _vm$terms.content) === null || _vm$terms$content === void 0 ? void 0 : (_vm$terms$content$rep = _vm$terms$content.replace) === null || _vm$terms$content$rep === void 0 ? void 0 : _vm$terms$content$rep.call(_vm$terms$content, /\n/g, '<br/>'))
    }
  }, [_vm._v(" 약관 내용 전달 ")])])], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.agree();
      }
    }
  }, [_vm._v("동의")])], 1)], 1)], 1)], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }