<template>
    <v-card-text class="pickup-item">
        <v-layout :align-start="$vuetify.breakpoint.mobile" :align-center="!$vuetify.breakpoint.mobile" class="row no-gutter pa-3 align-stretch d-block d-sm-flex">
            <v-flex shrink>
                <v-responsive width="128" height="128" aspect-ratio="1" class="mx-auto">
                    <v-img :src="cart.thumb?.path || cart.thumb?.src" max-width="100%"  max-height="100%" aspect-ratio="1" contain class="rounded-lg"/>
                </v-responsive>
            </v-flex>

            <v-flex class="mt-3 mt-sm-0">
                <v-responsive width="auto" height="100%">
                    <v-layout :class="{ 'd-block': $vuetify.breakpoint.mobile }" align-center fill-height>
                        <v-flex class="pa-sm-4 align-self-stretch">
                            <v-responsive>
                                <div class="subtitle-2 text-truncate">{{ cart.name }}</div>
                                <div class="body-1 mt-1"><b>{{ parseInt(cart.salePrice * cart.quantity).format() }}</b> 원</div>
                            </v-responsive>
                        </v-flex>

                        <v-flex shrink class="px-sm-4 mt-2">
                            <span>구매수량 : {{ cart.quantity }}</span>
                        </v-flex>
                    </v-layout>
                </v-responsive>
            </v-flex>
            
            
        </v-layout>
    </v-card-text>
</template>

<script>
import { mdiTruckDelivery } from "@mdi/js";
export default {
    props: ["value", "cart"],

    data(){
        return {
            mdiTruckDelivery,

            selected: this.$props.value || []
        }
    },
}
</script>

<style scoped>
.pickup-item {
    color: #000;
}
.pickup-item input {
    border: #000 1px inset; border-radius: 4px; width: 100%; max-width: 80px; height: 30px; text-align: center;
}
</style>